export const templatesLabels = [
  {
    id: 1,
    label: "email-invited-external"
  },
  {
    id: 2,
    label: "email-updated-external"
  },
  {
    id: 3,
    label: "email-deleted-external"
  },
  {
    id: 4,
    label: "tmp-code"
  },
  {
    id: 5,
    label: "email-invited"
  },
  {
    id: 6,
    label: "email-updated"
  },
  {
    id: 7,
    label: "email-deleted"
  },
  {
    id: 8,
    label: "status-changed"
  }
];

export const placeholders = [
  "GUEST_NAME",
  "GUEST_LAST_NAME",
  "HOST_NAME",
  "COMPANY_NAME",
  "RESOURCE_NAME",
  "MEETING_DATE",
  "MEETING_START_TIME",
  "MEETING_END_TIME",
  "LEVEL_ADDRESS",
  "LEVEL_CITY",
  "LEVEL_COUNTRY",
  "QRCODE",
  "HOST_NAME",
  "VISIT_STATUS",
  "MEETING_SUBJECT"
];
